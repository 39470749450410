.moderator-bottom-controls {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.simple-white-button {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: medium;
  font-weight: bold;
  outline: 0px;
  border: 0px;
  border-radius: 10%;
}

.message-box {
  position: fixed;
  bottom: 5% !important;
  left: 5%;
  background-color: #fff;
  opacity: 0.5;
  width: 20%;
  height: 30vh;
  overflow-y: hidden;
  z-index: 5;
}

.message-box2 {
  position: fixed;
  bottom: 5%;
  right: 5%;
  background-color: #fff;
  opacity: 0.5;
  width: 20%;
  height: 30vh;
  overflow-y: hidden;
  z-index: 5;
}

.single-message {
  /* border: 2px solid #09357d; */
  background: #f8f8f8;
  border-radius: 5px;
  margin: 10px;
  padding: 8px;
}

/* 
    .spacing {}
    
    .message {}
    
    .name {} */

.name {
  color: #2E3B62;
  font-size: 15px;
  margin-bottom: 5px;
}

.message {
  font-size: 13px;
  word-break: break-word;
}

.send-part {
  position: fixed;
  bottom: 0%;
  width: 35%;
  z-index: 5;
}

.message-editbox {
  /* margin-top: 5px;
    margin-bottom: 5px;
  
    padding: 5px;
    width: 80%;
    height: 2rem;
    outline-width: 0px;
    z-index: 5;
    opacity: 0.7; */
  background: #f8f8f8;
  border: 0px;
  height: 43px;
  line-height: 43px;
  padding: 0px 15px;
  color: #777777;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 45%;
  /* margin: 4px 0; */
  border: 1px transparent solid;
  width: 100%;
  outline: none;
}

.sendarrow {
  /* margin-top: 5px;
    margin-bottom: 5px;
    opacity: 0.7; */
  background: #f8f8f8;
  border: 0px;
  height: 43px;
  line-height: 43px;
  /* padding: 0px 15px; */
  color: #777777;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* width: 45%; */
  /* margin: 4px 2%; */
  border: 1px transparent solid;
  /* width: 100%; */
  outline: none !important;
}

.send-arrow-img {
  padding: 5px;
  width: 25px;
}

.bottomchatbox {
  position: absolute;
  background: white;
  bottom: 0px;
  z-index: 2000;
  min-width: 300px;
  max-width: 300px;
  /* height: 60%; */
  /* background-image: url("../images/002.png"); */
  background-position: center;
  background-repeat: no-repeat;
  /* padding: 10px; */
  left: 0px;
  border-radius: 5px 5px 0px 0px;
  font-family: 'Poppins', sans-serif !important;
  bottom: 108px;
}

.flexform {
  /* height: 70%; */
  overflow-y: auto;
  font-family: 'Poppins', sans-serif !important;
}

.sendsection {
  display: flex;
}

.header-chatbox {
  display: flex;
  align-items: center;
}

.header-title {
  margin-top: 0;
  margin-bottom: 0;
  color: #2E3B62;
  /* font-family: 'black', sans-serif !important; */
  width: 100%;
  font-size: 20px;
}

.scrolltype::-webkit-scrollbar {
  width: 6px;
}

.scrolltype::-webkit-scrollbar-track {
  background: transparent;
}

.scrolltype::-webkit-scrollbar-thumb {
  background-color: rgba(46, 59, 98, 0.6);
  border-radius: 20px;
  border: 3px solid transparent;
}

.scrolltypewhite::-webkit-scrollbar {
  width: 6px;
}

.scrolltypewhite::-webkit-scrollbar-track {
  background: transparent;
}

.scrolltypewhite::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  border: 3px solid transparent;
}

.chat-box {
  position: absolute;
  right: 20px;
  bottom: 0px;
  background: white;
  width: 300px;
  border-radius: 5px 5px 0px 0px;
}

.chat-head {
  width: inherit;
  height: 30px;
  background: #2E3B62;
  border-radius: 5px 5px 0px 0px;
}

.chat-head h2 {
  color: white;
  padding: 6px;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1rem;
}

.chat-head img {
  cursor: pointer;
  float: right;
  width: 20px;
  margin: 5px;
}

.chat-body {
  height: 300px;
  width: 100%;
  overflow: auto;
  margin-bottom: 45px;
}

@media(max-width:600px) {
  .chat-body {
    height: 230px;
  }
}

@media(min-width:1600px) {
  .chat-body {
    height: 31em;
  }

  .heigthchatbody {
    height: 28.1875em;
  }
}

.chat-textnormal {
  position: fixed;
  bottom: 0px;
  height: 45px;
  width: 300px;
  display: flex;
  bottom: 108px;
}

.pinicon {
  float: right;
}

.widthchaticon {
  width: 40px;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.comdisplay {
  display: block;
}

.mobdisplay {
  display: none;
}

@media(max-width:600px) {
  .comdisplay {
    display: none;
  }

  .mobdisplay {
    display: block;
  }

  .bottomtotopchat {
    bottom: 170px !important;
    /* transition: 0.5s ease-out; */
  }

  .bottomtotopchat1 {
    bottom: 100px !important;
    /* transition: 0.5s ease-out; */
  }

  .ishiddenchat {
    display: block !important;
  }

  .chaticonposition {
    position: fixed;
    z-index: 10;
    bottom: 120px;
    left: 20px;
  }

  .chaticonpositionbottom {
    bottom: 45px;
  }
}

@media only screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {
  .comdisplay {
    display: none;
  }

  .mobdisplay {
    display: block;
  }

  .bottomtotopchat {
    bottom: 120px !important;
    /* transition: 0.5s ease-out; */
  }

  .bottomtotopchat1 {
    bottom: 100px !important;
    /* transition: 0.5s ease-out; */
  }

  .ishiddenchat {
    display: block !important;
  }

  .chaticonposition {
    position: fixed;
    z-index: 10;
    bottom: 70px;
    left: 20px;
  }

  .chaticonposition2 {
    bottom: 170px;
  }

  .chaticonpositionbottom {
    bottom: 45px;
  }

  .chat-body {
    height: 150px !important;

  }
}
