/* @font-face {
  font-family: 'black';
  src: url('../../../fonts/proximanova-black-webfont.eot');
  src: url('../../../fonts/proximanova-black-webfont.eot?#iefix') format('embedded-opentype'), url('../../../fonts/proximanova-black-webfont.woff2') format('woff2'), url('../../../fonts/proximanova-black-webfont.woff') format('woff'), url('../../../fonts/proximanova-black-webfont.ttf') format('truetype'), url('../../../fonts/proximanova-black-webfont.svg#proxima_novablack') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bold';
  src: url('../../../fonts/proximanova-extrabold-webfont.eot');
  src: url('../../../fonts/proximanova-extrabold-webfont.eot?#iefix') format('embedded-opentype'), url('../../../fonts/proximanova-extrabold-webfont.woff2') format('woff2'), url('../../../fonts/proximanova-extrabold-webfont.woff') format('woff'), url('../../../fonts/proximanova-extrabold-webfont.ttf') format('truetype'), url('../../../fonts/proximanova-extrabold-webfont.svg#proxima_novaextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'regular';
  src: url('../../../fonts/proximanova-regular-webfont.eot');
  src: url('../../../fonts/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../../../fonts/proximanova-regular-webfont.woff2') format('woff2'), url('../../../fonts/proximanova-regular-webfont.woff') format('woff'), url('../../../fonts/proximanova-regular-webfont.ttf') format('truetype'), url('../../../fonts/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
} */

#bottommenu {
  width: 100%;
  height: 108px;
  /* background: #866ec7; */
  /* background: #005B31; */
  /* background: rgba(166, 29, 58, 0.5); */
  /* box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.25); */
  color: #fff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  position: fixed;
  z-index: 1000;
  bottom: 0px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold;
  background-image: linear-gradient(to right, rgba(20, 78, 130, 0.7), rgba(20, 78, 130, 0.7));
}

.mobmenu {
  display: none;
}

.widthmenuicon {
  display: none;
}

.flexicon {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 5px;
  margin-top: 5px;
}

@media(max-width:900px) {
  .flexicon {
    justify-content: start;
    overflow: auto;
  }
}

.flexiconspeaker {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 20px;
  margin-top: 30px;
}

.widthicon {
  width: 45px;
  margin: 0 auto;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

@media(max-width:700px) {
  .widthicon {
    width: 40px;
  }
}

.widthicon:hover {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
  transform: scale(0.8);
}

.widthicon:active {
  transform: scale(0.8);
}

/* .widthspeaker {
  margin: 0 auto;
} */

.flexcolumn {
  display: flex;
  flex-direction: column;
  width: 100px;
  margin: 3px 5px;
  cursor: pointer;
  min-width: 100px;
}

.flexcolumnspeaker {
  display: flex;
  flex-direction: column;
  /* width: 100px; */
  margin: 5px 10px;
  cursor: pointer;
}

.cenetrtext {
  text-align: center;
  font-size: 12px;
  /* font-weight: bold; */
}

.bottomtext {
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif !important;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.startcamerabtn {
  background-color: white;
  color: #000;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  outline: none !important;
  border: 0;
  width: 250px;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
  /* border-right: 2px solid #2E3B62; */
}

/* .startcamerabtn:hover {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
  transform: scale(0.9);

} */

.borderrightbtn {
  border-right: 1px solid #C5C6C6;
  height: 25px;
  margin-left: 2px;
  margin-right: 2px;
}

.LABEL {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font-size: 20px;
  color: black;
}

.red-text {
  color: red !important;
}

.green-text {
  color: #2E3B62;
}

.logoutbtnposition {
  position: fixed;
  right: 20px;
  cursor: pointer;
}

.mutebtnposition {
  position: fixed;
  left: 20px;
  display: flex;
  flex-direction: row;
}

.marginrightmutebtn {
  margin-right: 1rem;
}

.breakoutbutton {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.agendaButton {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.nursebreakout {
  padding-left: 2%;
}

.btnBlueGreen {
  z-index: 1001;
  color: #2E3B62;
  width: 150px;
  background-color: white;
  outline: none !important;
  border: 2px solid #2E3B62;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
  margin-top: 0;
  font-weight: bold;
  height: 40px;
}

.btnBlueGreen:hover {
  background-color: #2E3B62;
  border: 0;
  color: white;
}

.margin {
  margin-left: 10px;
}

/* 
.btnBlueGreen:hover {
  background-color: #00AE68 !important;
  border: 1px solid white !important;
  color: white !important;
} */

.active {
  background-color: #2E3B62;
  border: 0;
  color: white;
  outline: none !important;
}

.agendaImage {
  padding: 0px 30px 24px 30px !important;
  font-size: 13px;
  color: #666;
}

.modal {
  z-index: 2000;
  overflow: auto !important;
}

/* .scrollbar {
  background: #F5F5F5;
	overflow-y: scroll;
} */
#session1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#session1::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#session1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #2E3B62;
}

#session2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#session2::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#session2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #2E3B62;
}

.table .thead-dark th{
  background-color: #2E3B62;
}
.quickbtn {
  display: inline-block;
  padding: 15px;
  margin-right: 5px;
  height: 50px;
  min-width: 100px;
  background: #2E3B62;
  border: none;
  outline: none;
  color: white;
  font-family: inherit;
  font-weight: 400;
  font-size: 20px;
  border-radius: 3px;
  box-shadow: 0 5px 0px #2E3B62;
  border-bottom: 2px solid #4a5d99;
}

.btn-glow {
  color: #fff !important;
  display: inline-block;
  background: rgb(20, 78, 130);
  background: -moz-linear-gradient(left, #2E3B62 0%, #ffaa2f 100%);
  background: -webkit-linear-gradient(left, #2E3B62 0%, #ffaa2f 100%);
  background: linear-gradient(to right, #2E3B62 0%, #ffaa2f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8a61f8', endColorstr='#ec5fe7', GradientType=1);
  border: none;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  /* line-height: 56px; */
  letter-spacing: 0.07em;
  text-decoration: none !important;
  text-transform: uppercase;
  padding: 10px ;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  box-shadow: none;
  animation: glow 3s infinite;
  animation-timing-function: ease-out;
  position: relative;
  /* border-radius: 10px; */
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  z-index: 1000;
}

.btn-glow:hover {
  box-shadow: 0 0 0 10px #3daaa200;
  transition: .8s;
  -webkit-transition: .8s;
  z-index: 1000;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 20px #2E3B62;
    text-shadow: 0 0 0px #fff;
  }

  40% {
    box-shadow: 0 0 100px #005D62;
    text-shadow: 0 0 20px #fff;
  }

  100% {
    box-shadow: 0 0 20px #2E3B62;
    text-shadow: 0 0 1px #fff;
  }
}

.btn-hover-shine {
  position: fixed;
  bottom: 108px;
  left: 50%;
  transform: translate(-50%, 0px);
  outline: none !important;
  z-index: 1000;
}
@media(max-width:600px){
  .btn-hover-shine {
    width: 190px;
  }
}
/* .btn-hover-shinebottom {
  bottom: 0;
} */

.btn-hover-shine:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  right: 80%;
  width: 1px;
  background-color: #ffffff00;
  box-shadow: 0 0 20px #ffd2fe00, 0 0 10px #ffd2fe00, 0 0 5px #ffd2fe00, 0 0 2px #ffd2fe00;
  opacity: 1;
  z-index: 1000;
}

.btn-hover-shine:hover:after {
  right: 2%;
  background-color: #ffffffff;
  box-shadow: 0 0 30px rgb(210, 211, 255), 0 0 15px rgb(215, 210, 255), 0 0 7px rgb(215, 210, 255), 0 0 3px rgb(217, 210, 255);
  opacity: 0;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  z-index: 1000;
}

@media only screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {

  .btn-hover-shine {
    display: none;
  }

  .bottomaniamtion {
    transform: translateY(500px);
  }

  .mobmenu {
    display: block;
    position: fixed;
    bottom: 10px;
    left: 20px;
    /* background: white; */
    border-radius: 50%;
    /* padding: 10px; */
    /* box-shadow: 0 0 20px; */
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    z-index: 12;
    box-shadow: 0 5px 10px 2px rgb(0 0 0 / 30%);
    border-radius: 50%;
  }

  .widthmenuicon {
    width: 40px;
    display: block;
  }

  .mobmenuclose {
    display: none;
    position: fixed;
    bottom: 120px;
    left: 20px;
    /* background: white; */
    border-radius: 50%;
    /* padding: 10px; */
    /* box-shadow: 0 0 20px; */
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    z-index: 12;
    box-shadow: 0 5px 10px 2px rgb(0 0 0 / 30%);
    border-radius: 50%;
  }
}
